<template>
  <div class="page-visit-box">
    <div class="search-content-box">
      <div class="search-box">
        <el-input
          v-model="searchObj.keyword"
          v-debounce="[onSearch, 'input']"
          class="input mg-r10"
          clearable
          prefix-icon="el-icon-search"
          placeholder="搜索代表名称或客户名称"
          @clear="onSearch"
          @keydown.enter.native="onSearch"
        />
        <el-cascader
          v-model="searchObj.orgIds"
          clearable
          :options="orgList"
          :props="cascaderProps"
          :show-all-levels="false"
          placeholder="选择组织"
          @change="onSelectOrg"
        />
        <el-tooltip
          class="mg-r10"
          effect="light"
          content="将显示组织及所有下级组织数据"
          placement="right"
          style="font-size: 16px; margin-left: 3px; color: #5a5a5a;"
        >
          <svg-icon name="notes" />
        </el-tooltip>
        <span style="color: #5a5a5a;"> 拜访时间：</span>
        <el-date-picker
          v-model="searchObj.timeValue"
          class="mg-r10"
          type="daterange"
          format="yyyy年MM月dd日"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          :clearable="false"
          @change="onChangeDate"
        />
      </div>
      <div>
        <el-button
          v-if="isTopOrg"
          type="primary"
          plain
          @click="onJumpSetting"
        >
          拜访设置
        </el-button>
        <!-- 预留按钮 -->
        <!-- <el-button type="primary">查看数据</el-button> -->
      </div>
    </div>
    <el-table
      :data="dataList"
      style="width: 100%;"
    >
      <el-table-column
        prop="representName"
        label="代表名称"
      />
      <el-table-column
        prop="doctorName"
        label="拜访客户"
        align="center"
      />
      <el-table-column
        prop="tagName"
        label="拜访产品"
        align="center"
      />
      <el-table-column
        prop="knowledgePointName"
        label="产品关键信息"
        align="center"
        width="280"
        show-overflow-tooltip
      />
      <el-table-column
        prop="concept"
        label="医生观念"
        align="center"
      >
        <template slot-scope="{row}">
          <span :style="{color: onJsonInfo(row.concept).color}">{{ onJsonInfo(row.concept).text }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="coVisitName"
        label="协访人员"
        align="center"
      />
      <el-table-column
        prop="visitTime"
        label="拜访时间"
        align="center"
      />
      <el-table-column
        prop="orgLinkName"
        label="组织"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="prop"
        label="操作"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="onJumpDetail(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="center" style="padding: 20px;">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="searchObj.pageSize"
        :current-page="searchObj.pageNo"
        :total="totalCount"
        @current-change="onChangePageNo"
        @size-change="onChangePageSize"
      />
    </el-row>
  </div>
</template>

<script>
import {  mapGetters } from 'vuex'
import apiObjs from '@/apis/api-objs.js'

export default {
  name: 'VisitList',
  data() {
    return {
      selectedOrgId: '',
      cascaderProps: {
        children: 'child',
        label: 'orgName',
        value: 'orgId',
        disabled: 'isSelect',
        checkStrictly: true
      },
      searchObj: {
        keyword: '',
        orgIds: [],
        timeValue: [`${this.$moment().add(-1, 'month').format('YYYY-MM-DD')} 00:00:00`, `${this.$moment().format('YYYY-MM-DD')} 23:59:59`],
        pageNo: 1,
        pageSize: 10
      },
      dataList: [],
      totalCount: 0
    }
  },
  computed: {
    ...mapGetters(['orgList', 'isTopOrg'])
  },
  mounted() {
    this.onChangeDate()
  },
  methods: {
    onJsonInfo(item) {
      try {
        const obj = JSON.parse(item)
        if (typeof obj === 'object') {
          return obj
        } else {
          return {
            color: '',
            text: obj
          }
        }
      } catch (e) {
        return {color: '', text: item}
      }
    },
    // 搜索
    onSearch() {
      this.onGetList()
    },
    onSelectOrg() {
      this.onGetList()
    },
    onChangeDate() {
      console.log(this.searchObj)
      this.onGetList()
    },
    onJumpDetail(row) {
      console.log(row)
      this.$router.push({name: 'VisitItemDetail', params: {id: row.id}})
    },
    onJumpSetting() {
      this.$router.push({name: 'VisitSetting'})
    },
    onChangePageNo(val) {
      this.searchObj.pageNo = val
      this.onGetList(false)
    },
    onChangePageSize(val) {
      this.searchObj.pageSize = val
      this.onGetList()
    },
    onGetList(isReset = true) {
      if (isReset) {
        this.searchObj.pageNo = 1
      }
      const options = {
        ...apiObjs.customerVisitList,
        dataMaps: {
          orgId: states => states.orgIds?.slice(-1)[0] || '',
          startTime: states => states.timeValue[0],
          endTime: states => states.timeValue[1]
        }
      }
      this.$requireApi(options, this.searchObj).then(res => {
        const {data, totalCount} = res.data
        this.dataList = data
        this.totalCount = totalCount
      }, err => {
        this.$message.error(err.message)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-visit-box {
  background-color: #fff;
  margin: 10px;
}
.search-content-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.search-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
}
.input {
  width: 240px;
  ::v-deep .el-input__inner {
    border-radius: 20px;
  }
}
.mg-r10 {
  margin-right: 10px;
}
</style>
