<template>
  <page-main title back>
    <el-tabs v-model="activeName">
      <el-tab-pane label="字段管理" name="first">
        <div class="mg-b10">
          <el-input
            v-model="keyWord"
            class="input"
            clearable
            prefix-icon="el-icon-search"
            placeholder="搜索字段名称"
          />
        </div>
        <el-table
          :data="getList"
          style="width: 100%;"
        >
          <el-table-column
            prop="name"
            label="字段名"
          />
          <el-table-column
            prop="type"
            label="类型"
            align="center"
          >
            <template slot-scope="scope">
              {{ onGetTypeValue(scope.row.type) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="isRequired"
            label="是否必须"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="!scope.row.isEditEnable">{{ scope.row.isRequired ? '是' : '否' }}</span>
              <el-switch
                v-else
                v-model="scope.row.isRequired"
                v-debounce="[onChangeStatus, 'click']"
                active-color="#13ce66"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="isShow"
            label="是否显示"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="!scope.row.isEditEnable">{{ scope.row.isShow ? '是' : '否' }}</span>
              <el-switch
                v-else
                v-model="scope.row.isShow"
                v-debounce="[onChangeStatus, 'click']"
                active-color="#13ce66"
              />
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="拜访设置" name="second">
        <div style="padding: 20px 20px 60px;">
          <div>
            <span class="mg-r10">是否支持代表编辑拜访记录</span>
            <el-switch
              v-model="visitSetObj.isSupportEdit"
              v-debounce="[onSaveSetting, 'click']"
              active-color="#13ce66"
            />
          </div>
          <div v-show="visitSetObj.isSupportEdit" class="editor-box">
            <div>
              <el-radio-group v-model="visitSetObj.timeControlType" @change="onChangeRadio">
                <el-radio :label="1">按照时间控制</el-radio>
                <el-radio :label="2">按照时间周期</el-radio>
              </el-radio-group>
            </div>
            <div style="margin-top: 15px;">
              <span>{{ getText }} </span>
              <el-input-number
                v-show="visitSetObj.timeControlType === 1"
                v-model="visitSetObj.timeControlValue"
                :min="0"
                style="width: 120px;"
              />
              <el-select
                v-model="visitSetObj.timeUnitType"
                placeholder=""
                style="width: 60px; margin-left: 10px;"
                @change="onChangeTime"
              >
                <el-option
                  v-for="item in getOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <span> 不可编辑</span>
            </div>
            <div class="save-box">
              <el-button type="primary" @click="onSaveSetting">保存</el-button>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <span class="mg-r10">是否开启下次拜访规划</span>
            <el-switch
              v-model="visitSetObj.isEnableNextVisit"
              v-debounce="[onSaveSetting, 'click']"
              active-color="#13ce66"
            />
          </div>
          <div style="color: #969696;">下次规划包含：下次拜访日期及下次拜访内容</div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="打卡设置" name="third">
        <div style="padding: 20px 20px 60px;">
          <div>
            <span class="mg-r10">是否开启打卡功能</span>
            <el-switch
              v-model="visitCardObj.isEnableClock"
              v-debounce="[onSaveCard, 'click']"
              active-color="#13ce66"
            />
          </div>
          <div v-if="visitCardObj.isEnableClock" class="editor-box">
            <div style="margin-top: 15px;">
              <span>迁出打卡与迁入打卡范围允许误差 </span>
              <el-input-number v-model="visitCardObj.clockRange" :min="500" style="width: 120px;" />
              <span> 单位：米</span>
            </div>
            <div style="margin-top: 15px;">
              <span>迁出打卡与迁入打卡不能小于 </span>
              <el-input-number v-model="visitCardObj.clockRangeMinMinute" :min="0" style="width: 120px;" />
              <span> 单位：分钟</span>
            </div>
            <div class="save-box">
              <el-button type="primary" @click="onSaveCard">保存</el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </page-main>
</template>

<script>
export default {
  name: 'VisitSetting',
  data() {
    return {
      activeName: 'first',
      keyWord: '',
      dataList: [],
      cacheSet: {
        1: {
          timeUnitType: 1
        },
        2: {
          timeUnitType: 3
        }
      },
      visitSetObj: {
        isSupportEdit: true,
        timeControlType: 1,
        timeControlValue: '24',
        timeUnitType: 1,
        isEnableNextVisit: true
      },
      visitCardObj: {
        isEnableClock: true,
        clockRange: 2000,
        clockRangeMinMinute: 10
      }
    }
  },
  computed: {
    getList() {
      const pattern = new RegExp(this.keyWord, 'i')
      const matches = this.dataList.filter(obj => pattern.test(obj.name))
      return matches
    },
    getText() {
      return this.visitSetObj.timeControlType === 1 ? '创建时间大于' : '创建拜访后，超过'
    },
    getOptions() {
      const obj = {
        1: [
          {
            value: 0,
            label: '分钟'
          },
          {
            value: 1,
            label: '小时'
          }
        ],
        2: [
          {
            value: 3,
            label: '当天'
          },
          {
            value: 4,
            label: '本周'
          }
        ]
      }
      return obj[String(this.visitSetObj.timeControlType)] || []
    }
  },
  mounted() {
    this.onGetList()
    this.onGetSetting()
    this.onGetCard()
  },
  methods: {
    onGetTypeValue(type) {
      const obj = {
        1: '选项',
        2: '图片',
        3: '附件',
        4: '文本'
      }
      return obj[type] || ''
    },
    onChangeRadio() {
      this.visitSetObj.timeUnitType = this.cacheSet[String(this.visitSetObj.timeControlType)].timeUnitType
    },
    onChangeTime() {
      this.cacheSet[String(this.visitSetObj.timeControlType)].timeUnitType = this.visitSetObj.timeUnitType
    },
    onGetList() {
      this.$axios.get(this.$API.customerVisitField).then(res => {
        this.dataList = res.data
      }, err => {
        this.$message.error(err.message)
      })
    },
    onChangeStatus() {
      this.$axios.post(this.$API.customerVisitUpdateField, this.dataList).then(() => {
        this.onGetList()
        this.$message.success('修改成功')
      }, err => {
        this.$message.error(err.message)
      })
    },
    onGetSetting() {
      this.$axios.get(this.$API.customerVisitSetting).then(res => {
        this.visitSetObj = res.data
        this.onChangeTime()
      }, err => {
        this.$message.error(err.message)
      })
    },
    onUpdateSetting() {
      this.$axios.post(this.$API.customerVisitUpdateSetting, this.visitSetObj).then(() => {
        this.onGetSetting()
        this.$message.success('修改成功')
      }, err => {
        this.$message.error(err.message)
      })
    },
    onSaveSetting() {
      this.onUpdateSetting()
    },
    onGetCard() {
      this.$axios.get(this.$API.customerVisitCard).then(res => {
        this.visitCardObj = res.data
      }, err => {
        this.$message.error(err.message)
      })
    },
    onUpdateCard() {
      this.$axios.post(this.$API.customerVisitUpdateCard, this.visitCardObj).then(() => {
        this.onGetCard()
        this.$message.success('修改成功')
      }, err => {
        this.$message.error(err.message)
      })
    },
    onSaveCard() {
      this.onUpdateCard()
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  width: 240px;
  ::v-deep .el-input__inner {
    border-radius: 20px;
  }
}
.editor-box {
  width: 400px;
  background: #f6f7fb;
  padding: 20px;
  margin-top: 10px;
}
.save-box {
  margin-top: 15px;
  padding-top: 20px;
  border-top: 1px solid #e1e1e1;
  text-align: center;
}
.mg-b10 {
  margin-bottom: 10px;
}
.mg-r10 {
  margin-right: 10px;
}
</style>
