<template>
  <page-main title back>
    <el-form label-width="120px">
      <el-form-item label="所在组织:">
        <span>{{ detailInfo.orgLinkName }}</span>
      </el-form-item>
      <el-form-item label="代表名:">
        <span>{{ detailInfo.representName }}</span>
      </el-form-item>
      <el-form-item label="拜访客户:">
        <span>{{ detailInfo.doctorName }}</span>
      </el-form-item>
      <el-form-item label="拜访产品:">
        <span>{{ detailInfo.tagName }}</span>
      </el-form-item>
      <el-form-item label="关键信息:">
        <span>{{ detailInfo.knowledgePointName }}</span>
      </el-form-item>
      <el-form-item label="医生观念:">
        <span :style="{color: onJsonInfo(detailInfo.concept).color}">{{ onJsonInfo(detailInfo.concept).text }}</span>
      </el-form-item>
      <el-form-item label="拜访时间:">
        <span>{{ detailInfo.visitTime }}</span>
      </el-form-item>
      <el-form-item label="创建时间:">
        <span>{{ detailInfo.createTime }}</span>
      </el-form-item>
      <el-form-item label="关联打卡:">
        <div class="card-box">
          <div>
            <span>迁入位置：</span>
            <span>{{ detailInfo.clockInAddress || '无' }}</span>
          </div>
          <div>
            <span>迁入时间：</span>
            <span>{{ detailInfo.clockInTime || '无' }}</span>
          </div>
          <div>
            <span>迁入备注：</span>
            <span>{{ detailInfo.clockInRemark || '无' }}</span>
          </div>
          <div style="margin-top: 5px;">
            <span>迁出位置：</span>
            <span>{{ detailInfo.clockOutAddress || '无' }}</span>
          </div>
          <div>
            <span>迁出时间：</span>
            <span>{{ detailInfo.clockOutTime || '无' }}</span>
          </div>
          <div>
            <span>迁出备注：</span>
            <span>{{ detailInfo.clockOutRemark || '无' }}</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="协访人员:">
        <span>{{ detailInfo.coVisitName || '无' }}</span>
      </el-form-item>
      <el-form-item label="下次拜访日期:">
        <span>{{ detailInfo.nextVisitTime || '无' }}</span>
      </el-form-item>
      <el-form-item label="下次拜访内容:">
        <span>{{ detailInfo.nextVisitContext || '无' }}</span>
      </el-form-item>
    </el-form>
  </page-main>
</template>

<script>
export default {
  name: 'VisitItemDetail',
  data() {
    return {
      detailInfo: {}
    }
  },
  mounted() {
    this.onGetDetail()
  },
  methods: {
    onGetDetail() {
      this.$axios.get(this.$API.customerVisitDetail, {params: {id: this.$route.params.id}}).then(res => {
        this.detailInfo = res.data || {}
      }, err => {
        this.$message.error(err.message)
      })
    },
    onJsonInfo(item) {
      try {
        const obj = JSON.parse(item)
        if (typeof obj === 'object') {
          return obj
        } else {
          return {
            color: '',
            text: obj
          }
        }
      } catch (e) {
        return {color: '', text: item}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-box {
  width: fit-content;
  background: #f6f7fb;
  padding: 10px 20px;
  color: #5a5a5a;
}
</style>
